import React, {useEffect} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const Reviews = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    return (
      <>
        <div className="home-reviews-box">
              <h2 className="section-headers">Client Reviews</h2>
              
            

            <Swiper
                modules={[Navigation, Pagination, Scrollbar]}
                spaceBetween={50}
                slidesPerView={1}
                navigation={true}
                speed={500}
                loop
                pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
            >
            <SwiperSlide> <div className="reviews-boxes">
                  <p><i>"I can’t recommend Nebula Construction and Dylan enough! 
                    He’s diligent, consistent, does high quality work and quickly as well. 
                    I’ve recommended him to many of my clients and everyone had glowing reviews of his work as well. Dylan backs up his work with integrity - something you don’t see too often these days."</i></p>
                    <br></br>
                  <h3>Kori L</h3>
                  <p>Richmond, Virginia</p>
                </div>
            </SwiperSlide>


            <SwiperSlide><div className="reviews-boxes">
                  <p><i>"We hired Dylan for a small repair job in our house. He and his crew were on time, efficient, and performed quality work. We will absolutely be hiring him again for future projects."
                  </i></p>
                    <br></br>
                  <h3>Patrick H</h3>
                  <p>Midlothian, Virginia</p>
                </div>
            </SwiperSlide>

            <SwiperSlide><div className="reviews-boxes">
                  <p><i>"Prior to using Nebula Construction I had several companies come out and give me quotes; and when I called to schedule, they never called back. Dylan and his team provided a quote within a couple of days and came out to do the work within a week of project approval.

The team communicated with me frequently and finished the job quickly. I was extremely pleased with the work and would highly recommend the company.

I was putting my house on the market and had a short timeline. Everyone was professional and the end result was just what I wanted!"
                  </i></p>
                    <br></br>
                  <h3>Mary S</h3>
                  <p>Midlothian, Virginia</p>
                </div>
            </SwiperSlide>
            
            <SwiperSlide><div className="reviews-boxes">
                  <p><i>"Dylan S. with Nebula has helped multiple clients of mine (I’m an RVA area Realtor) and I continue to send referrals to him when someone I know has a need. He has shown professionalism AND what my clients have described as fair pricing for the great quality of work.

I recommend ☑️"
                  </i></p>
                    <br></br>
                  <h3>Paul W</h3>
                  <p>Richmond, Virginia</p>
                </div>
            </SwiperSlide>

            <SwiperSlide><div className="reviews-boxes">
                  <p><i>"Nebula did an outstanding job repairing our home after water damage. They communicated with us consistently, did outstanding work, and at a reasonable price. Highly recommend."
                  </i></p>
                    <br></br>
                  <h3>Chase P</h3>
                  <p>Mechanicsville, Virginia</p>
                </div>
            </SwiperSlide>

          
            <SwiperSlide><div className="reviews-boxes">
                  <p><i>"Dylan at Nebula Construction did a fantastic job on a short fused kitchen remodel and other home repairs on my home in Richmond. He and his crew were very professional, under took the work with great speed and quality, and above all the price was extremely competitive. I am very happy how the kitchen came out transforming from a 1950s kitchen to modern standards. My home was built in 1921 and has had few updates through the years. Dylan and crew was able to transform my home to contemporary use while maintaining the original details. I would recommend Dylan and Nebula construction to anyone with a challenging project. I believe you will be happy and impressed as well."
                  </i></p>
                    <br></br>
                  <h3>James V</h3>
                  <p>Richmond, Virginia</p>
                </div>
            </SwiperSlide>

            <SwiperSlide><div className="reviews-boxes">
                  <p><i>"My plaster ceiling in a bedroom was falling. The crew came, put in drywall and painted the ceiling and walls. Really refreshed and set the bedroom off beautifully. They were efficient and careful of my home. Everyone was personable and professional. I will call on them for future projects."
                  </i></p>
                    <br></br>
                  <h3>Penny S</h3>
                  <p>Richmond, Virginia</p>
                </div>
            </SwiperSlide>

            <SwiperSlide><div className="reviews-boxes">
                  <p><i>"Excellent work done quickly.  Very responsive."
                  </i></p>
                    <br></br>
                  <h3>Michael P</h3>
                  <p>Richmond, Virginia</p>
                </div>
            </SwiperSlide>

            <SwiperSlide><div className="reviews-boxes">
                  <p><i>"  Working with Dylan was so easy. His communication was great and he was really helpful and supportive after my house fire. If I have any projects in the future, I will definitely reach out!"</i></p>
                    <br></br>
                  <h3>Sara A</h3>
                  <p>Richmond, Virginia</p>
                </div>
            </SwiperSlide>

            <SwiperSlide><div className="reviews-boxes">
                  <p><i>"Dylan installed our new attic ladder. 
                    He provided a reasonable estimate, clearly communicating all expectations of what would be completed with the project, and completed the project quickly and professionally. 
                    I would recommend his services and would hire him again!"
                  </i></p>
                    <br></br>
                  <h3>Cara W</h3>
                  <p>Richmond, Virginia</p>
                </div>
            </SwiperSlide>

            <SwiperSlide><div className="reviews-boxes">
                  <p><i>"Dylan did a wonderful job remodeling my side porch. He replaced the door glass, hung the curtains, installed a ceiling fan and a cabinet. It is now my favorite room of the house. He is friendly and professional. I will definitely be using him again."</i></p>
                    <br></br>
                  <h3>Dawn M</h3>
                  <p>Richmond, Virginia</p>
                </div>
            </SwiperSlide>
            {/* <SwiperSlide>Slide 4</SwiperSlide> */}
      
            </Swiper>
            </div>
      </>
    );
  };
  
  export default Reviews;

